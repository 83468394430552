import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery, Link } from "gatsby";
const _ = require("lodash");

const TagList = ({ language, selectedTag }) => (
  <StaticQuery
    query={graphql`
      query TagListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                tags
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const posts = data.allMarkdownRemark.edges;

      let tags = [];
      // Iterate through each post, putting all found tags into `tags`
      posts.forEach((edge) => {
        if (_.get(edge, `node.frontmatter.tags`)) {
          tags = tags.concat(edge.node.frontmatter.tags);
        }
      });
      // Eliminate duplicate tags
      tags = _.uniq(tags);

      return (
        <div className="border-b pb-2 mb-5 border-gray-400 text-lg">
          <span className="font-bold mr-4">Latest news</span>
          <Link
            to={`${language === "en" ? "" : language}/blog`}
            className={`inline-block mr-4 lowercase ${
              selectedTag ? "text-gray-700" : "text-primary"
            }`}
          >
            <span className="mr-3">/</span>
            All
          </Link>
          {tags &&
            tags.map((tag) => (
              <Link
                key={tag}
                to={`${language === "en" ? "" : language}/blog/${_.kebabCase(
                  tag
                )}`}
                className={`inline-block mr-4 lowercase ${
                  tag === selectedTag ? "text-primary" : "text-gray-700"
                }`}
              >
                <span className="mr-3">/</span>
                {tag}
              </Link>
            ))}
        </div>
      );
    }}
  />
);

TagList.propTypes = {
  language: PropTypes.string,
  selectedTag: PropTypes.string,
};

export default TagList;
